import React from "react"

import AboutLayout from "../components/layouts/aboutLayout"

const JoinPage = () => (
  <AboutLayout pageTitle="Join Now">
    <h2>Fill out the application to join now!</h2>
    <br />
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2023_Membership_Application.pdf">Membership Application</a>
  </AboutLayout>
)

export default JoinPage
